<template>
  <div>
    <ServerError v-if="ServerError" />
    <PageLoader :storage="appLoading" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center pa-3>
      <v-flex xs12>
        <v-layout wrap justify-start>
          <v-flex xs12>
            <span class="itemHeading1">Report Case</span>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center pt-3>
          <v-flex xs12 lg7 pr-3>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <v-text-field
                  outlined
                  append-icon="mdi-magnify"
                  append-outer-icon="mdi-close"
                  label="Location Name"
                  placeholder="Enter Location Name"
                  v-model="locationname"
                  @click:append="plotLocation"
                  @click:append-outer="resetMap"
                  class="itemform"
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md4 sm4 lg4>
                <v-text-field
                  outlined
                  label="District"
                  v-model="reportdistrict"
                  disabled
                  class="itemform"
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md4 sm4 lg4 pl-lg-2 pl-md-2 pl-sm-2>
                <v-text-field
                  outlined
                  label="Latitude"
                  v-model="lattitude"
                  disabled
                  class="itemform"
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md4 sm4 lg4 pl-lg-2 pl-md-2 pl-sm-2>
                <v-text-field
                  outlined
                  label="Longitude"
                  v-model="longitude"
                  disabled
                  class="itemform"
                  dense
                ></v-text-field>
              </v-flex>
              <!-- <v-flex xs12 pt-0 class="text-end itemValue">
                <v-btn color="error" @click="resetMap" plain>
                  <span>RESET MAP</span>
                </v-btn>
              </v-flex> -->
              <v-flex xs12 md6 sm6 lg6>
                <v-select
                  :items="animalTypes"
                  label="Type Of Animal"
                  item-text="name"
                  item-value="_id"
                  outlined
                  v-model="animaltype"
                  class="itemform"
                  dense
                ></v-select>
              </v-flex>
              <v-flex xs12 md6 sm6 lg6 pl-lg-2 pl-md-2 pl-sm-2>
                <v-select
                  :items="situations"
                  item-text="name"
                  item-value="_id"
                  label="Situation"
                  outlined
                  v-model="situation"
                  class="itemform"
                  dense
                ></v-select>
              </v-flex>
              <v-flex xs12>
                <v-select
                  :items="threatLevels"
                  label="Threat Level"
                  outlined
                  v-model="threatlevel"
                  class="itemform"
                  dense
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center pa-5 style="border: solid 1px">
              <v-flex xs12 text-left>
                <v-layout wrap justify-start>
                  <v-flex
                    xs12
                    sm6
                    md4
                    lg4
                    pa-5
                    v-for="(items, index) in displayImage"
                    :key="index"
                  >
                    <v-img :src="items" fill-height height="100%" width="100%">
                      <v-layout wrap>
                        <v-flex text-right pa-0>
                          <v-avatar color="#FF3434" size="15">
                            <v-icon
                              color="#FFF"
                              small
                              @click="removeImage(index)"
                            >
                              mdi-close
                            </v-icon>
                          </v-avatar>
                        </v-flex>
                      </v-layout>
                    </v-img>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-end>
                  <v-flex xs12 text-right>
                    <v-btn
                      color="blue-grey"
                      dark
                      small
                      @click="$refs.uploadDoc.click()"
                      class="itemform"
                      ><span> UPLOAD IMAGES </span>
                      <v-icon right dark> mdi-cloud-upload </v-icon></v-btn
                    >
                  </v-flex>
                  <input
                    v-show="false"
                    id="file1"
                    ref="uploadDoc"
                    multiple
                    type="file"
                    accept=".svg, .png, .jpg, .jpeg"
                    @change="uploadDoc"
                  />
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center pt-3 pb-3>
              <!-- <v-flex xs6 class="text-center">
                <v-btn color="error" @click="resetMap">
                  <span>RESET MAP</span>
                </v-btn>
              </v-flex> -->
              <v-flex xs12 class="text-center">
                <v-btn
                  block
                  tile
                  :color="appColor"
                  light
                  :ripple="false"
                  depressed
                  @click="handleSubmit"
                  class="itemValue"
                >
                  <span style="color: #fff">SUBMIT</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 lg5 pr-3>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <div id="mapp"></div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyDPNXCjI6IJbDqwJcJwGuJUfNWxfMLSe-U&libraries=visualization,drawing"></script>
<script>
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      panCardImages: [],
      lattitude: "",
      longitude: "",
      locationname: "",
      animaltype: "",
      situation: "",
      reportdistrict: "",
      msg: "",
      timeout: 5000,
      threatlevel: "",
      animalTypes: [],
      situations: [],
      threatLevels: ["High", "Medium", "Low"],
      map: "",
      mapData: {},
      checkboxItems: [],
      selectedItems: [],
      filterData: [],
      lat: "",
      lon: "",
      onMarker: [],
      map3: "",
      categorylist2: [],
      selected: "",
      newMarker: [],
      defaultChecked: true,
      geocoder: null,
      marker: null,
      prevLocation: null,
      keralaBounds: {
        north: 12.924,
        south: 8.074,
        west: 74.855,
        east: 77.509,
      },

      docs1: [],
      docs1Public: [],
      docsPublic: [],
      docs: [],
      displayImage: [],
      userList: [],
      userArray: [],
      newArray: [],
      doc: null,
      file: null,
      keyword: "",
      registerAssets: {},
      formData: new FormData(),
      assetId: "",
      search: "",
      Rules: [(value) => !!value || "Required."],
      fileArray: [],
      byte: "",
      kb: "",
      mb: "",
      gb: "",
    };
  },
  mounted() {
    this.mapData = this.storage;
    this.initMap();
  },
  beforeMount() {
    this.getanimalData();
    this.getscenarioData();
  },
  methods: {
    uploadDoc(event) {
      this.docs1 = event.target.files || event.dataTransfer.files;

      if (this.docs.length < 10) {
        for (let i = 0; i < this.docs1.length; i++) {
          if (this.fileArray.includes(this.docs1[i].name)) {
            this.msg = "Duplicate File";
            this.showSnackBar = true;
          } else {
            const urls = URL.createObjectURL(this.docs1[i]);
            this.displayImage.push(urls);
            this.docs.push(this.docs1[i]);
            console.log("file", this.docs1[i]);
            console.log("haii img", this.docs);
          }
          this.fileArray.push(this.docs1[i].name);
          this.checkbox = false;
          // this.checkbox1 = false;
          this.checkbox3 = false;
          // this.checkbox2 = false;
          this.docs1[i].sizeCopy = this.docs1[i].size + "Bytes";
          if (this.docs1[i].size >= 1024) {
            this.byte = this.docs1[i].size;
            this.kb = this.byte / 1024;
            if (this.kb >= 1024) {
              this.mb = (this.byte / 1048576).toFixed(2);
              if (this.mb >= 1024) {
                this.gb = (this.byte / 1073741824).toFixed(2);
                this.docs1[i].sizeCopy = this.gb + "GB";
              } else {
                this.docs1[i].sizeCopy = this.mb + "MB";
              }
            } else {
              this.docs1[i].sizeCopy = this.kb.toFixed(2) + "KB";
            }
          }
        }
        // this.addToArray();
      } else {
        this.msg = "Maximum 10 can be uploaded";
        this.showSnackBar = true;
      }
    },
    removePrivate(i) {
      this.fileArray = this.fileArray.filter((x) => this.docs[i].name != x);
      this.docs.splice(i, 1);
    },
    panCardUpload(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.panCardImages.push(e.target.result);
        };
        reader.readAsDataURL(files[i]);
      }
      console.log("images", this.panCardImages);
    },
    removeImage(index) {
      this.displayImage.splice(index, 1);
      this.docs.splice(index, 1);
      this.fileArray.splice(index, 1);
    },

    initMap() {
      this.map3 = new google.maps.Map(document.getElementById("mapp"), {
        center: { lat: 10.8505, lng: 76.2711 },
        zoom: 7,
      });
      this.geocoder = new google.maps.Geocoder();
    },
    // plotLocation() {
    //   if (this.locationname) {
    //     this.geocoder.geocode(
    //       { address: this.locationname },
    //       (results, status) => {
    //         if (status === "OK") {
    //           const location = results[0].geometry.location;
    //           if (this.isInsideKerala(location.lat(), location.lng())) {
    //             this.addMarker(location);
    //             this.map3.setCenter(location);
    //             this.lattitude = location.lat();
    //             this.longitude = location.lng();
    //             this.prevLocation = location;
    //           } else {
    //             this.msg = "The location must be inside Kerala.";
    //             this.showSnackBar = true;
    //           }
    //         } else {
    //           console.error(
    //             "Geocode was not successful for the following reason: " + status
    //           );
    //         }
    //       }
    //     );
    //   }
    // },
    getDistrict(addressComponents) {
  // Iterate over address components to find the district
  for (let component of addressComponents) {
    if (component.types.includes("administrative_area_level_3")) {
      return component.long_name; // Return the district name
    }
  }
  return null; // Return null if district not found
},
    plotLocation() {
      if (this.locationname) {
        this.geocoder.geocode(
          { address: this.locationname },
          (results, status) => {
            if (status === "OK" && results.length > 0) {
              const location = results[0].geometry.location;
              if (this.isInsideKerala(location.lat(), location.lng())) {
                this.addMarker(location);
                this.map3.setCenter(location);
                this.lattitude = location.lat();
                this.longitude = location.lng();
                this.prevLocation = location;

                // Log the full geocoding result for debugging
                console.log("Geocode result:", results[0]);

                // Fetch district from address_components
                const addressComponents = results[0].address_components;
                const district = this.getDistrict(addressComponents);
                if (district) {
                  this.reportdistrict = district;
                  console.log("District:", this.reportdistrict); // Log district
                } else {
                  console.log("District not found in address components.");
                }
              } else {
                this.msg = "The location must be inside Kerala.";
                this.showSnackBar = true;
              }
            } else {
              console.error("Geocode failed: " + status);
            }
          }
        );
      }
    },

    // addMarker(location) {
    //   if (this.marker) {
    //     this.marker.setPosition(location);
    //   } else {
    //     this.marker = new google.maps.Marker({
    //       position: location,
    //       map: this.map3,
    //       title: this.locationname,
    //       draggable: true,
    //     });
    //     this.onMarker.push(this.marker);
    //   }

    //   google.maps.event.addListener(this.marker, "dragend", (event) => {
    //     const newLocation = event.latLng;
    //     if (this.isInsideKerala(newLocation.lat(), newLocation.lng())) {
    //       this.lattitude = newLocation.lat();
    //       this.longitude = newLocation.lng();
    //       this.reverseGeocode(newLocation);
    //       this.prevLocation = newLocation;
    //     } else {
    //       this.msg = "The marker must be inside Kerala.";
    //       this.showSnackBar = true;
    //       this.marker.setPosition(this.prevLocation);
    //     }
    //   });
    // },
    addMarker(location) {
    // Clear existing marker if any
    if (this.marker) {
      this.marker.setMap(null); // Remove the existing marker from the map
    }

    // Create a new marker
    this.marker = new google.maps.Marker({
      position: location,
      map: this.map3,
      draggable: true, // Make the marker draggable
    });

    // Add event listener for drag end
    google.maps.event.addListener(this.marker, "dragend", (event) => {
      const draggedLocation = event.latLng; // Get the new location
      this.currentLatitude = draggedLocation.lat(); // Update latitude
      this.currentLongitude = draggedLocation.lng(); // Update longitude

      // Fetch district and location name for the new position
      this.fetchDistrict(draggedLocation);
    });
  },

    isInsideKerala(lat, lng) {
      const bounds = this.keralaBounds;
      return (
        lat >= bounds.south &&
        lat <= bounds.north &&
        lng >= bounds.west &&
        lng <= bounds.east
      );
    },
    fetchDistrict(location) {
  // Use the geocoder to fetch district for the new location
  this.geocoder.geocode(
    { location: location },
    (results, status) => {
      if (status === "OK" && results.length > 0) {
        const addressComponents = results[0].address_components;
        const district = this.getDistrict(addressComponents);
        const locationName = results[0].formatted_address; // Get the location name

        // Update the variables with the new values
        this.reportdistrict = district; // Update the district variable
        this.locationname = locationName; // Update the location name variable
        this.lattitude = location.lat(); // Update latitude
        this.longitude = location.lng(); // Update longitude
        // console.log("Updated District:", this.reportdistrict); // Log the updated district
        // console.log("Updated Location Name:", this.locationname); // Log the updated location name
        // console.log("Updated Latitude:", this.lattitude); // Log the updated latitude
        // console.log("Updated Longitude:", this.longitude); // Log the updated longitude
      } else {
        console.error("Geocode failed after dragging: " + status);
      }
    }
  );
},
    reverseGeocode(location) {
      this.geocoder.geocode({ location: location }, (results, status) => {
        if (status === "OK" && results[0]) {
          const address = results[0].formatted_address;
          this.locationname = address;
        } else {
          console.error(
            "Geocode was not successful for the following reason: " + status
          );
        }
      });
    },
    resetMap() {
      // Clear the marker from the map
      if (this.marker) {
        this.marker.setMap(null);
        this.marker = null;
      }

      // Clear the fields
      this.lattitude = "";
      this.longitude = "";
      this.locationname = "";
      this.reportdistrict = "";
      this.panCardImages = [];
    },
    toggleSublist(index) {
      this.checkboxItems[index].sublistVisible =
        !this.checkboxItems[index].sublistVisible;
    },
    addMarkers(projects) {
      const infowindow = new google.maps.InfoWindow();

      projects.forEach((project) => {
        project.allLocations.forEach((location) => {
          const lat = location.location[1];
          const lon = location.location[0];
          const locationLatLng = new google.maps.LatLng(lat, lon);

          const marker = new google.maps.Marker({
            position: locationLatLng,
            map: this.map3,
            title: location.place,
            id: project._id,
            icon: project.icon
              ? {
                  url: `https://wtiadmin.in/file/get/${project.icon}`,
                  scaledSize: new google.maps.Size(40, 40),
                }
              : null,
          });
          this.onMarker.push(marker);
          this.newMarker.push({
            id: project._id,
            position: locationLatLng,
          });

          marker.addListener("click", () => {
            const description = project.description
              ? project.description
              : "No description available";
            let content = `
          <div style="max-width: 300px; max-height: 250px; overflow-y: auto;">
            <h3 style="font-size: 16px; justify-content: space-between;">${location.place}</h3>
            <p>${description}</p>
        `;

            if (project.url) {
              content += `<a href="${project.url}" target="_blank">Learn more</a>`;
            }

            content += `</div>`;

            infowindow.setContent(content);
            infowindow.open(this.map3, marker);
          });
        });
      });
    },
    clearMarkers(checkboxId) {
      const isMainCategory = this.checkboxItems.some(
        (item) => item._id === checkboxId
      );
      const isSubCategory = this.checkboxItems.some(
        (item) =>
          item.projects &&
          item.projects.some((subItem) => subItem._id === checkboxId)
      );

      if (isMainCategory || isSubCategory) {
        var Marker = this.newMarker.filter((x) => x.id == checkboxId);
        for (let j = 0; j < Marker.length; j++) {
          for (let i = 0; i < this.onMarker.length; i++) {
            if (this.onMarker[i].id == Marker[j].id) {
              this.onMarker[i].setMap(null);
              this.onMarker.splice(i, 1);
              break;
            }
          }
        }
      }
    },
    clearAllMarkers() {
      for (let i = 0; i < this.onMarker.length; i++) {
        this.onMarker[i].setMap(null);
      }
      this.onMarker = [];
      this.newMarker = [];
    },
    validateForm() {
      let missingFields = [];

      if (!this.locationname) missingFields.push("Location Name");
      if (!this.animaltype) missingFields.push("Type Of Animal");
      if (!this.situation) missingFields.push("Situation");
      if (!this.threatlevel) missingFields.push("Threat Level");
      if (this.panCardImages.length === 0)
        missingFields.push("Upload At least one image");

      if (missingFields.length > 0) {
        this.msg =
          "Please fill in the following fields: " + missingFields.join(", ");
        this.showSnackBar = true;
        return false;
      }
      return true;
    },
    handleSubmit() {
      console.log('Map object:', this.map3);
      if (!this.locationname) {
        this.msg = "Please Provide Location Name.";
        this.showSnackBar = true;
        return;
      }
      if (!this.animaltype) {
        this.msg = "Please Provide Type Of Animal.";
        this.showSnackBar = true;
        return;
      }
      if (!this.situation) {
        this.msg = "Please Provide The Situation.";
        this.showSnackBar = true;
        return;
      }
      if (!this.threatlevel) {
        this.msg = "Please Provide The Threat Level.";
        this.showSnackBar = true;
        return;
      }
      const now = new Date();
      const offset = 5.5 * 60;
      const localTime = new Date(
        now.getTime() + offset * 60 * 1000
      ).toISOString();
      const formData = new FormData();
      for (let i = 0; i < this.docs.length; i++) {
        formData.append("reportImages", this.docs[i]);
      }
      formData.append("userthreatlevel", this.threatlevel);
      formData.append("isInlocation", false);
      formData.append("locationname", this.locationname);
      formData.append("scenarioId", this.situation);
      formData.append("animalId", this.animaltype);
      formData.append("lat", this.lattitude);
      formData.append("lon", this.longitude);
      formData.append("reportTime", localTime);
      formData.append("district", this.reportdistrict);

      this.appLoading = true;
      axios({
        method: "POST",
        url: "/report/add",
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
        data: formData,
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = "Case Reported successfully!";
              this.showSnackBar = true;
              this.appLoading = false;
              this.threatlevel = "";
              this.locationname = "";
              this.situation = "";
              this.animaltype = "";
              this.lattitude = "";
              this.longitude = "";
              this.time = "";
              this.displayImage = "";
              this.marker.setMap(null);
              this.marker = null;
              window.location.reload();
              // this.$router.push({
              //   path: "/admin/adminDashboard?id=assignedCases",
              // });
            }
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getanimalData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/animal/list",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.animalTypes = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getscenarioData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/scenario/list",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.situations = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style>
#mapp {
  height: 577px !important;
  width: 100%;
}
.uploaded-image {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.uploaded-image img {
  max-width: 100%;
  max-height: 200px;
}

.uploaded-image v-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(255, 255, 255, 0.8);
}
</style>
